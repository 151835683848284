
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import AddProductDrawer from '@/layout/header/partials/course/AddProductDrawer.vue';
import EditproductInfoDrawer from '@/layout/header/partials/course/EditproductInfoDrawer.vue';
import SetupProductInfoDrawer from '@/layout/header/partials/course/SetupProductInfoDrawer.vue';
import SetupRawProductInfoDrawer from '@/layout/header/partials/course/SetupRawProductInfoDrawer.vue';
import ViewProductDrawer from '@/layout/header/partials/course/ViewProductDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'product-list',
  components: {
    Datatable,
    AddProductDrawer,
    EditproductInfoDrawer,
    SetupProductInfoDrawer,
    SetupRawProductInfoDrawer,
    ViewProductDrawer
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      tableHeader: [
        {
          name: 'Type',
          key: 'product_type',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Name',
          key: 'product_name',
          // sortable: true,
        },
        {
          name: 'Category',
          key: 'category_name',
          // sortable: true,
        },
        {
          name: 'Size',
          key: 'size_measurement',
          // sortable: true,
        },
        {
          name: 'Weight',
          key: 'weight_measurement',
          // sortable: true,
        },
        {
          name: 'Brand',
          key: 'brand',
          // sortable: true,
        },
        {
          name: 'Model',
          key: 'model',
          // sortable: true,
        },
        {
          name: 'Color',
          key: 'color',
          // sortable: true,
        },
        {
          name: 'Unit Price',
          key: 'unit_price',
          // sortable: true,
        },
        {
          name: 'Country Of Origin',
          key: 'country_origin',

          // sortable: true,
        },
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      await this.getProductData();
      this.emitter.on('product-added', async () => {
        await this.getProductData();
      });
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    viewProduct(product_data)
    {
      this.emitter.emit('product_data',product_data);
    },
    setupProduct(product_data)
    {
      this.emitter.emit('product_data',product_data);
    },
    
    async getProductData() {
      await ApiService.get('finmanInvoice/product_info/list')
        .then((response) => {
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    editProduct(data) {
      this.emitter.emit('product-edit-type', data);
    },

    Deleteproduct(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/product/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('product-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
